import { View } from "react-native";
import React from "react";
import { Text } from "react-native-elements";
import { LinearProgress } from "react-native-elements";
import Spacer from "./Spacer";
import { Board } from "./Board";
 
import RavencoinReader from "@ravenrebels/ravencoin-reader";
/*
    Check for unconfirmed transactions every 20 seconds.
*/
export default function Status({ addresses }) {
  const [memPool, setMemPool] = React.useState([]);

  const noDependencies = [];
  React.useEffect(function pollMempool() {
    const poll = async () => {
      const asdf = await RavencoinReader.getAddressMempool(addresses);
      setMemPool(asdf);
    };
    poll();
    const interval = setInterval(poll, 20000);
    return function cleanup() {
      clearInterval(interval);
    };
  }, noDependencies);

 
  if (memPool.length === 0){
    return null;
  }
  return (
    <View>
      <Spacer />
      <Board>
        <Text h4>Unconfirmed transactions</Text>

        <LinearProgress color="primary" />
      </Board>
      <Spacer />
    </View>
  );
}
 