import * as Clipboard from "expo-clipboard";
import React from "react";
import { ScrollView, Switch, View, Alert, Platform } from "react-native";
import { Icon, Input, Text } from "react-native-elements";
import Button from "../components/Button";

import Spacer from "../components/Spacer";
import theme from "../theme.json";
import utils from "../utils";
const RavencoinHandler = require("../_ravencoinhandler");
import * as Random from "expo-random";

function generateMnemonic() {
  const bytes = Random.getRandomBytes(16);
  return RavencoinHandler.entropyToMnemonic(bytes);
}
export function convertMnemonicStringToObject(mnemonic) {
  const splitty = mnemonic.split(" ");
  const values = {};
  splitty.map((value, index) => {
    values["" + (index + 1)] = value;
  });
  return values;
}

let hideMode = true;
export default function Settings({ eraseWallet, mnemonic, setMnemonic }) {
  const [values, setValues] = React.useState({});
  const [_, setTrigger] = React.useState("");

  React.useEffect(() => {
    const v = convertMnemonicStringToObject(mnemonic);
    setValues(v);
    setTrigger(new Date());
  }, [mnemonic]);
  const onChangeText = (key, value) => {
    const inner = (key, value) => {
      values[key] = value;
      setValues(values);
      setTrigger(Math.random());
    };

    //Did we paste in a full mnemonic in the first field?
    if (key === "1" && value.split(" ").length > 5) {
      const s = value.split(" ");
      s.map((v, i) => inner(i + 1 + "", v));
    } else {
      inner(key, value);
    }
  };

  const submit = () => {
    try {
      console.log("In submit of Settings");
      console.log("About to convert values to string");
      const v = convertObjectToMnemonicString(values);

      //Validate
      console.log("Will check if valid");
      const isValid = RavencoinHandler.isMnemonicValid(v);
      console.log("Is valid?", isValid);
      if (isValid === false) {
        const text = `
        Seems to be something wrong with the 12 words.
        If you use non-English words you get this warning even
        if your words are OK`;
        alert(text);
      }

      console.log("About to call setMnemonic");
      setMnemonic(v);
    } catch (e) {
      console.error(e);
      alert("" + e);
    }
  };

  const toggleHideMode = (event) => {
    hideMode = !hideMode;
    setTrigger(new Date()); //Super ugly to force update
  };

  const copyToClipboard = () => {
    const promise = Clipboard.setStringAsync(mnemonic);
    promise.then(() => {
      alert("Words copied to memory");
    });
    promise.catch(() => {
      alert("Could NOT copy words to memory, try again");
    });
  };

  return (
    <ScrollView style={{ paddingLeft: "10%", paddingBottom: 300 }}>
      <Spacer />
      <View>
        <Text>Hide words</Text>
        <Switch value={hideMode} onValueChange={toggleHideMode} />
      </View>
      <Spacer />
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Text style={{ paddingRight: 10 }}>
          These 12 words are the keys to your wallet. Back them up manually. Do
          not share them with anyone. Do NOT use this app on a shared device.
        </Text>
        {mnemonic.length > 20 && (
          <View>
            <Text h4>
              Phrase (12 words){" "}
              <Icon
                size={14}
                reverse
                name="file-copy"
                color={theme.palette.primaryColor}
                onPress={copyToClipboard}
              />
            </Text>
            <Spacer />
          </View>
        )}

        {(!mnemonic || mnemonic.length < 20) && (
          <Button
            buttonStyle={{
              maxWidth: 200,
              borderRadius: 20,
              backgroundColor: "rgb(46, 62, 128)",
            }}
            title="Generate new wallet!"
            onPress={async () => {
              const m = generateMnemonic();

              setMnemonic(m);
            }}
          ></Button>
        )}
        <View>
          <Text>Tip: you can paste in the full phrase in the first field</Text>
        </View>
        <Spacer />
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Word
            label="1"
            value={values["1"]}
            onChangeText={(value) => onChangeText("1", value)}
          ></Word>
          <Word
            label="2"
            value={values["2"]}
            onChangeText={(value) => onChangeText("2", value)}
          ></Word>
        </View>

        <View style={{ display: "flex", flexDirection: "row" }}>
          <Word
            label="3"
            value={values["3"]}
            onChangeText={(value) => onChangeText("3", value)}
          ></Word>
          <Word
            label="4"
            value={values["4"]}
            onChangeText={(value) => onChangeText("4", value)}
          ></Word>
        </View>

        <View style={{ display: "flex", flexDirection: "row" }}>
          <Word
            label="5"
            value={values["5"]}
            onChangeText={(value) => onChangeText("5", value)}
          ></Word>
          <Word
            label="6"
            value={values["6"]}
            onChangeText={(value) => onChangeText("6", value)}
          ></Word>
        </View>

        <View style={{ display: "flex", flexDirection: "row" }}>
          <Word
            label="7"
            value={values["7"]}
            onChangeText={(value) => onChangeText("7", value)}
          ></Word>
          <Word
            label="8"
            value={values["8"]}
            onChangeText={(value) => onChangeText("8", value)}
          ></Word>
        </View>

        <View style={{ display: "flex", flexDirection: "row" }}>
          <Word
            label="9"
            value={values["9"]}
            onChangeText={(value) => onChangeText("9", value)}
          ></Word>
          <Word
            label="10"
            value={values["10"]}
            onChangeText={(value) => onChangeText("10", value)}
          ></Word>
        </View>

        <View style={{ display: "flex", flexDirection: "row" }}>
          <Word
            label="11"
            value={values["11"]}
            onChangeText={(value) => onChangeText("11", value)}
          ></Word>
          <Word
            label="12"
            value={values["12"]}
            onChangeText={(value) => onChangeText("12", value)}
          ></Word>
        </View>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          maxWidth: 300,
        }}
      >
        <Button
          buttonStyle={{
            maxWidth: 200,
            backgroundColor: "rgb(46, 62, 128)",
          }}
          title="Save!"
          onPress={submit}
        ></Button>

        {mnemonic !== utils.mnemonicDefault && (
          <Button
            buttonStyle={{
              maxWidth: 200,
              backgroundColor: "rgb(46, 46, 46)",
            }}
            title="Clear wallet!"
            onPress={() => {
              if (Platform.OS === "web") {
                const c = confirm(
                  "Are you sure you want to erase your wallet?"
                );

                if (c === true) {
                  eraseWallet();
                }
              } else {
                Alert.alert(
                  "Erase wallet",
                  "Are you sure you will start all over?",
                  [
                    {
                      text: "Cancel",
                      onPress: () => console.log("Cancel Pressed"),
                      style: "cancel",
                    },
                    { text: "OK", onPress: () => eraseWallet },
                  ]
                );
              }
            }}
          ></Button>
        )}
      </View>
    </ScrollView>
  );
}

export function convertObjectToMnemonicString(keyValue) {
  let result = "";
  for (let i = 1; i <= 12; i++) {
    const v = keyValue["" + i];
    if (!v) {
      throw new Error("Word " + i + " cannot be blank");
    }
    result = result + " " + v;
  }
  return result.trim();
}
function Word({ label, onChangeText, value }) {
  return (
    <View
      style={{
        marginBottom: 10,
        marginTop: 10,
        minWidth: 100,
        maxWidth: 150,
        width: 150,
      }}
    >
      <Input
        value={value || ""}
        enterkeyhint="next"
        label={label}
        autoCapitalize="none"
        onChangeText={onChangeText}
        secureTextEntry={hideMode}
      />
    </View>
  );
}
