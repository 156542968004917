import * as React from "react";
import { View } from "react-native";
import { Icon, Text } from "react-native-elements";
import theme from "../theme.json";
import SvgQRCode from "react-native-qrcode-svg";
import * as Clipboard from "expo-clipboard";
import { Board } from "./Board";
import RavencoinReader from "@ravenrebels/ravencoin-reader";

export default function Receive({ addresses }) {
  const [address, setAddress] = React.useState(null);
  if (!addresses || addresses.length < 1) {
    return null;
  }
  React.useEffect(() => {
    async function work() {
      //Return first external address with no history
      let counter = 0;
      for (const a of addresses) {
        if (counter % 2 === 1) {
          console.log("Checking balance for", a);
          const balance = await RavencoinReader.getAssetBalance(a);

          if (Object.keys(balance).length === 0) {
            setAddress(a);
            console.log("Setting address to", a);
            break;
          }
        }
        counter++;
      }
    }
    work();
  }, [addresses]);

  if (!address) {
    return null;
  }

  const copyToClipboard = () => {
    alert("Copied " + address);
    Clipboard.setStringAsync(address);
  };
 
  return (
    <Board>
      <View>
        <Text h4 style={{ color: theme.palette.primaryColor }}>
          Receive{" "}
          <Icon
            size={14}
            reverse
            name="file-copy"
            color={theme.palette.primaryColor}
            onPress={copyToClipboard}
          />
        </Text>
        <SvgQRCode value={address} />
      </View>
    </Board>
  );
}
