import * as React from "react";
import { TouchableOpacity, View } from "react-native";
import { Text } from "react-native-elements";
import { shadowStyle } from "./shadowStyle";
import AssetIcon from "./AssetIcon";
const _style = { ...shadowStyle, backgroundColor: "rgb(241, 91, 34);" };
export function AssetButton({ name, onPress, style }) {
  const textSmall = name.split("#")[0];
  const textLarge = "# " + name.split("#")[1];
  const s = {
    ..._style,
    ...style,
    display: "flex",
    flexDirection: "row"
  };
  return (
    <View key={name}>
      <TouchableOpacity style={s} onPress={onPress}>
        <View>
          <AssetIcon name={name} />
        </View>
        <View style={{ flex: 2 }}>
          <Text style={{ fontSize: 13, color: "white" }}>{textSmall}</Text>
          <Text style={{ fontSize: 20, color: "white" }}>{textLarge}</Text>
        </View>
 
      </TouchableOpacity>
    </View>
  );
}
