const ONE_HUNDRED_MILLION = 100000000;
import * as DataHandler from "./DataHandler";
console.log("DataHandler", DataHandler);
const mnemonicDefault = "           "; //eleven blank spaces

function getAddressByUniqueAsset(uniqueAsset) {
  
  return DataHandler.getAddressForAsset(uniqueAsset);
}

async function getMnemonicFromStorage(SecureStore, Platform) {
  let mnemonic =
    Platform.OS === "web"
      ? localStorage.getItem("mnemonic")
      : await SecureStore.getItemAsync("mnemonic");
  return mnemonic;
}

async function isPasswordNeeded(SecureStore, Platform) {
  //Only use passwords on the web
  if (Platform.OS !== "web") {
    return false;
  }
  const m = await getMnemonicFromStorage(SecureStore, Platform);

  //NO mnemonic means totally new user
  if (!m) {
    return true;
  }

  if (m.length > 5 && m.indexOf(" ") > -1) {
    //This "wallet" was setup before we introduced passwords
    return false;
  }

  return true;
}
export default {
  getAddressByUniqueAsset,

  isPasswordNeeded,
  mnemonicDefault,
  ONE_HUNDRED_MILLION,
};
