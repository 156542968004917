import { WHEN_UNLOCKED_THIS_DEVICE_ONLY } from "expo-secure-store";
import { View } from "react-native";
import { Text } from "react-native-elements";
import theme from "../theme.json";
const utils = require("../utils");

 
export function Balance({ balance }) {
  let displayBalance = null;

  if (balance && balance.balance) {

    balance.balance.map(function (item) {
      if (item.assetName === "RVN") {
        displayBalance = (item.balance / utils.ONE_HUNDRED_MILLION).toLocaleString();
      }
    });

  }

  return (
    <View>
      <Text h4 style={{ color: theme.palette.primaryColor }}>
        Balance
      </Text>
      <Text h5 style={{ color: theme.palette.primaryColor }}>
        {displayBalance} RVN
      </Text>
    </View>
  );
}
