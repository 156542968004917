import React from "react";
import { ScrollView, Text, View, Animated } from "react-native";

import { AssetsList } from "../components/AssetsList";
import { Balance } from "../components/Balance";
import { Board } from "../components/Board";
import Receive from "../components/Receive";
import Spacer from "../components/Spacer";
import Status from "../components/Status";

export function Home({ addresses, balanceInfo, selectUniqueAsset }) {
  if (!balanceInfo) {
    return null;
  }

  const balance = balanceInfo.balance;
 
  let buttonText = "Update pending....";
  if (balanceInfo.pendingUpdate === false) {
    buttonText = "Update!";
  }

  const fadeAnim = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 255,
      duration: 1000,
      useNativeDriver: false,
    }).start();
  }, []);

  const backgroundInterpolation = fadeAnim.interpolate({
    inputRange: [0, 255],
    outputRange: ["rgb(200,200,200)", "rgb(245,245,245)"],
  });

  return (
    <ScrollView>
      <Animated.View
        style={{
          flexDirection: "column",
          justifyContent: "space-around",
          minHeight: 800,
          paddingLeft: "5%",
          paddingRight: "5%",
          backgroundColor: backgroundInterpolation,
        }}
      >
        <Spacer />
        <Status addresses={addresses} />
        <AssetsList
          balance={balance}
          selectUniqueAsset={selectUniqueAsset}
        ></AssetsList>
        <View>
          <Board>
            <Balance balance={balance} />
          </Board>
        </View>
        <Spacer />
        <Receive addresses={addresses} balance={balance} />
        <Spacer />
      </Animated.View>
    </ScrollView>
  );
}
