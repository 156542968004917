import { Image } from "react-native";
export default function AssetIcon({ name }) {
  const styles = {
    backgroundColor: "rgb(46, 62, 128)",
    borderRadius: 30,
    height: 30,
    marginRight: 10,
    width: 30,
  };
  return (
    <Image
      style={styles}
      source={{
        uri:
          "https://rvn-explorer-mainnet.ting.finance/thumbnail?assetName=" +
          encodeURIComponent(name),
      }}
    />
  );
}
