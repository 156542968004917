import React, { useContext } from "react";
import { Platform, RefreshControl, ScrollView, View } from "react-native";
import { Text } from "react-native-elements";
import { SafeAreaProvider } from "react-native-safe-area-context";
import AppContext from "./AppContext";
import Button from "./components/Button";
import MyHeader from "./components/MyHeader";
import Routes from "./routes";
import useDoWeNeedMoreAddresses from "./useDoWeNeedMoreAddresses";
import utils from "./utils";
import { Home } from "./views/Home";
import Settings from "./views/Settings";
import SignIn from "./views/SignIn";
const RavencoinHandler = require("./_ravencoinhandler");

/*const mnemonicDefault =
  "natal hada sutil año sólido papel jamón combate aula flota ver esfera";*/

//Key is the address, value is an object with path, WIF, private key etc
const rawAddressInfo = {};
let rendered = 0;
export default function AppWorker() {
  const { eraseWallet, mnemonic, saveMnemonic, signOut, useBalance } =
    useContext(AppContext);

  const [showDebug, setShowDebug] = React.useState(false);
  const [route, setRoute] = React.useState(Routes.HOME);
  const [addresses, setAddresses] = React.useState([]);

  const [triggerUpdateDate, setTriggerUpdateDate] = React.useState(new Date());
  const [signInInfo, setSignInInfo] = React.useState({});

  const balanceInfo = useBalance(addresses, triggerUpdateDate);

  const doWeNeedMoreAddresses = useDoWeNeedMoreAddresses(
    addresses,
    balanceInfo
  );

  //"override" setMnemonic to store to secure store
  const setMnemonic = async (dataOriginal) => {
    await saveMnemonic(dataOriginal);

    const empty = [];

    //On change mnemonic, clear addresses and trigger load balance
    //Clear addresses and all in a while

    setAddresses(empty);
    setTriggerUpdateDate(new Date());

    //Route back to home
    setRoute(Routes.HOME);
  };
  //Every X minutes, run an update
  React.useEffect(() => {
    const update = () => {
      setTriggerUpdateDate(new Date());
    };
    const time = 1 * 60 * 1000;
    const i = setInterval(update, time);

    const cleanUp = () => {
      clearInterval(i);
    };

    return cleanUp;
  }, []);

  //Generate public addresses server side instead?
  //https://ethereum.stackexchange.com/questions/60139/getting-addresses-using-a-path-causes-the-error-could-not-derive-hardened-child

  //Good idea for balance/list of tokens/asset but does not give private key so still bad performance on IOS.

  //Server generates addresses and checks balances, and response back with path.
  //When we sign in, we check the path and generate the private key, should do.

  const mnemonicValid = mnemonic.length > 20;
  if (doWeNeedMoreAddresses && mnemonicValid === true) {
    deriveAddresses(mnemonic, addresses, setAddresses);
  }

  //If no mnemonic force route to SETTINGS
  const _route = mnemonic.trim() ? route : Routes.SETTINGS;

  const selectUniqueAsset = async (name) => {
    const n = { ...signInInfo };
    n.uniqueAsset = name;
    console.log("Asking for info about", name);
 
    n.address = await utils.getAddressByUniqueAsset( name);
    console.log("Received address", n.address);
    n.privateKey = RavencoinHandler.toPrivateKey(
      rawAddressInfo[n.address].privateKey
    );

    setSignInInfo(n);
    setRoute(Routes.SIGN_IN);
  };

  const onRefresh = () => {
    setTriggerUpdateDate(new Date());
  };
  const refreshing = (balanceInfo && balanceInfo.pendingUpdate) || false;

  return (
    <SafeAreaProvider style={{}}>
      <MyHeader
        balanceInfo={balanceInfo}
        route={_route}
        setRoute={setRoute}
        setTriggerUpdateDate={setTriggerUpdateDate}
        signOut={signOut}
      />

      <ScrollView
        refreshControl={
          <RefreshControl refreshing={false} onRefresh={onRefresh} />
        }
      >
        {_route === Routes.SETTINGS && (
          <Settings
            eraseWallet={eraseWallet}
            setMnemonic={setMnemonic}
            mnemonic={mnemonic}
          ></Settings>
        )}
        {_route === Routes.HOME && (
          <Home
            addresses={addresses}
            balanceInfo={balanceInfo}
            position={addresses.length > 0 ? addresses.length / 2 : 0}
            selectUniqueAsset={selectUniqueAsset}
            setTriggerUpdateDate={setTriggerUpdateDate}
          ></Home>
        )}

        {_route === Routes.SIGN_IN && (
          <SignIn
            balanceInfo={balanceInfo}
            signInInfo={signInInfo}
            onSignedIn={() => {
              setRoute(Routes.HOME);
            }}
          ></SignIn>
        )}

        <Button
          buttonStyle={{ padding: 10, maxWidth: 100, width: 100, fontSize: 10 }}
          titleStyle={{
            color: "white",
            fontSize: 12,
          }}
          title="Toggle debug info"
          onPress={() => setShowDebug(!showDebug)}
        ></Button>
        {showDebug && (
          <View>
            <Text>
              We are at position{" "}
              {addresses.length > 0 ? addresses.length / 2 : 0}
            </Text>

            <Text>Rendered {rendered++}</Text>
            <View>
              <Text h3>Debug</Text>
              <Text h4>Addreses</Text>
              {addresses.map((a, i) => {
                const b =
                  balanceInfo && balanceInfo.balance && balanceInfo.balance[a];

                if (!b) {
                  return null;
                }
                const styles =
                  JSON.stringify(b).length > 10 ? { marginBottom: 20 } : {};
                return (
                  <View style={styles} key={"view " + a}>
                    <Text key={a} style={{ fontFamiliy: "Courier" }}>
                      {rawAddressInfo[a].path} {a}
                    </Text>
                    <Text key={a + "_balance"}>{JSON.stringify(b)}</Text>
                  </View>
                );
              })}
            </View>
          </View>
        )}
      </ScrollView>
    </SafeAreaProvider>
  );
}
function deriveAddresses(mnemonic, addresses, setAddresses) {
  const _addresses = [];
  const network = "rvn";
  const hdKey = RavencoinHandler.getHDKey(network, mnemonic);
  //const path = "m/44'/175'/0'/0/0";

  //Web is a lot faster than IOS/Android

  let TIMES = 50;
  if (Platform.OS !== "web") {
    //Slightly less addresses the first time.
    //Deriving addresses is a slow operation on IOS/Android
    TIMES = addresses.length === 0 ? 10 : 20;
  }

  let position = addresses.length > 2 ? addresses.length / 2 : 0;
  for (let i = 0; i < TIMES; i++) {
    const external = RavencoinHandler.getAddressByPath(
      network,
      hdKey,
      "m/44'/175'/0'/0/" + position
    );
    const internal = RavencoinHandler.getAddressByPath(
      network,
      hdKey,
      "m/44'/175'/0'/1/" + position
    );

    _addresses.push(external.address);
    _addresses.push(internal.address);

    rawAddressInfo[external.address] = external;
    rawAddressInfo[internal.address] = internal;

    position++;
  }

  const a = addresses.concat(_addresses);

  setAddresses(a);
}
