export const shadowStyle = {
  backgroundColor: "rgb(255, 255, 255)",
  borderRadius: 20,
  marginBottom: 30,
  padding: 20,
  shadowColor: "rgb(100, 100, 100)",
  shadowRadius: 6,
  shadowOpacity: 1,
  shadowOffset: {
    width: -3,
    height: 3,
  },
};
