import Constants from "expo-constants";
import * as SecureStore from "expo-secure-store";
import * as React from "react";
import { ActivityIndicator, Platform, View } from "react-native";
import AppContext from "./AppContext";
import AppWorker from "./AppWorker";
import theme from "./theme.json";
import useBalanceLive from "./useBalance";
import utils from "./utils";
import { Entrance } from "./views/Entrance";

const EncryptionUtils = require("./EncryptionUtils");
//Need to polyfill some crypto stuff
import { polyfillWebCrypto } from "expo-standard-web-crypto";
polyfillWebCrypto();

//Add error handling ONLY for web
try {
  window.onerror = function (message, source, lineno, colno, error) {
    alert("Global error: " + error.message + ", lineno: " + lineno);
    return true;
  };
} catch (e) {}

export default function App() {
  //At startup check for saved mnemonic
  //Do NOT start the app until we have read mnemonic, on IOS this is an async operation

  //Decide if we should use live data or mocked data (production or local development)
  //useBalanceMock will read local file instead of using the server

  const [password, setPassword] = React.useState(null);
  const [isPasswordNeeded, setIsPasswordNeeded] = React.useState(true);

  utils.isPasswordNeeded(SecureStore, Platform).then(setIsPasswordNeeded);

  //What mnemonic do we get the very first time the user loads the app?
  //Invokes async operations, will not be set immediately
  const startupMnemonic = useMnemonic(password);

  //Nothing to render before we know if we need password or not
  if (isPasswordNeeded === null) {
    return <Loading />;
  }
  //User current mnemonic value, user might change mnemonic in the app
  let [mnemonicInClearText, setMnemonicInClearText] = React.useState(null);

  const eraseWallet = () => {
    saveMnemonic(null);
    setPassword(null);

    //alert("Hm, now we should reload no?");
  };
  //A proxy for set mnemonic with validation
  const setMnemonic = (m) => {
    if (!m) {
      return;
    }

    if (!m || m === "null") {
      m = utils.mnemonicDefault;
      saveMnemonic(null);
    }

    setMnemonicInClearText(m);
  };

  if (!startupMnemonic) {
    return <Loading />;
  }

  //Some wallets do not have password encryption, they are old
  if (
    startupMnemonic &&
    isEncrypted(startupMnemonic) === false &&
    !mnemonicInClearText
  ) {
    mnemonicInClearText = startupMnemonic;
  }

  //If we dont have clear text mnemonic, go back to default mnemonic, in case of new user
  if (!mnemonicInClearText) {
    mnemonicInClearText = utils.mnemonicDefault;
  }

  //Show the Set/Enter password screen
  if (isPasswordNeeded && !password) {
    try {
      return (
        <Entrance
          mnemonic={startupMnemonic}
          setMnemonic={setMnemonic}
          setPassword={setPassword}
        />
      );
    } catch (e) {
      alert("" + e);
    }
  }

  //Are we using live data or using mocked data in local environment?
  const uBalance =
    Constants.debugMode === true ? useBalanceLive : useBalanceLive;

  const signOut = () => {
    setPassword(null);
  };
  const saveMnemonic = async (mnemonic) => {
    let newValue = mnemonic;

    //Password should always be nullish on non-web no?
    if (password && mnemonic) {
      newValue = await EncryptionUtils.aesGcmEncrypt(mnemonic, password);
    }

    //IF NULL, REMOVE THE ENTRY
    if (mnemonic === null) {
      if (Platform.OS === "web") {
        localStorage.removeItem("mnemonic");
      } else if (Platform.OS !== "web") {
        await SecureStore.deleteItemAsync("mnemonic", newValue);
      }
    } else {
      //If web
      if (Platform.OS === "web") {
        localStorage.setItem("mnemonic", newValue);
      } else if (Platform.OS !== "web") {
        await SecureStore.setItemAsync("mnemonic", newValue);
      }
    }
    setMnemonicInClearText(mnemonic);
  };

  if (!mnemonicInClearText) {
    return <Loading />;
  }
  try {
    return (
      <AppContext.Provider
        value={{
          saveMnemonic,
          mnemonic: mnemonicInClearText,
          useBalance: uBalance,
          startupMnemonic,
          eraseWallet,
          signOut,
        }}
      >
        <AppWorker />
      </AppContext.Provider>
    );
  } catch (e) {
    alert("" + e);
  }
}

function Loading() {
  return (
    <View style={{ flex: 1 }}>
      <View
        style={{ flex: 1, backgroundColor: theme.palette.primaryColor }}
      ></View>
      <View style={{ flex: 1, backgroundColor: theme.palette.primaryColor }}>
        <ActivityIndicator
          size="large"
          color="white"
          style={{ transform: [{ scale: 2 }] }}
        />
      </View>

      <View
        style={{ flex: 1, backgroundColor: theme.palette.primaryColor }}
      ></View>
    </View>
  );
}

function isEncrypted(startupMnemonic) {
  if (!startupMnemonic) {
    return false;
  }

  const isNew = startupMnemonic === utils.mnemonicDefault;
  if (isNew === true) {
    return true;
  }

  /* If we have a mnemonic value and that value does not cointain SPACE that means
  that the value is encrypted */

  if (startupMnemonic && startupMnemonic.indexOf(" ") === -1) {
    return true;
  }

  return false;
}

function useMnemonic(password) {
  const [mnemonic, setMnemonic] = React.useState(null);

  React.useEffect(() => {
    let getMnemonic = async () => {
      let mnemonic =
        Platform.OS === "web"
          ? localStorage.getItem("mnemonic")
          : await SecureStore.getItemAsync("mnemonic");

      if (!mnemonic) {
        mnemonic = utils.mnemonicDefault;
      }
      setMnemonic(mnemonic);
    };
    getMnemonic();
  }, [password]);

  return mnemonic;
}
