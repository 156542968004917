import axios from "axios";
import * as Clipboard from "expo-clipboard";
import * as React from "react";
import { TextInput, View } from "react-native";
import { Icon, Text } from "react-native-elements";
import { Board } from "../components/Board";
import Button from "../components/Button";
import Spacer from "../components/Spacer";
import theme from "../theme.json";

import Scanner from "./Scanner";
const EncryptionUtils = require("../EncryptionUtils");
const RavencoinHandler = require("../_ravencoinhandler");
import { polyfillWebCrypto } from "expo-standard-web-crypto";

polyfillWebCrypto();

const titleStyle = {
  fontSize: 12,
};

export default function SignIn({ signInInfo, onSignedIn }) {
  const address = signInInfo.address;
  const privateKey = signInInfo.privateKey;

  const [message, setMessage] = React.useState("");
  const [signature, setSignature] = React.useState("");

  const [showScanner, setShowScanner] = React.useState(false);

  const [orderRef, setOrderRef] = React.useState(null);

  const [order, setOrder] = React.useState(null);
  //Find which address contains unique asset

  const [postURL, setPostURL] = React.useState(null);
  React.useEffect(() => {
    async function work() {
      if (!orderRef) {
        return;
      }
      const URL = "https://idp.ravenrebels.com/orders/" + orderRef;

      const axiosResponse = await axios.get(URL);
      const m = EncryptionUtils.atob(axiosResponse.data.userVisibleData);

      setMessage(m);

      setOrder(axiosResponse.data);
    }
    work();
  }, [orderRef]);
  React.useEffect(() => {
    //Do NOT generate a signature for an empty message
    //Just set signature to blank string
    if (!message) {
      setSignature("");
      return;
    }

    const s = RavencoinHandler.sign(message, privateKey);
    setSignature(s);
    //Create a signature
  }, [message]);
  return (
    <View
      style={{
        flexDirection: "column",
        justifyContent: "space-around",
        minHeight: 800,
        paddingLeft: "5%",
        paddingRight: "5%",
      }}
    >
      <Board>
        <Text h1 style={{ color: theme.palette.primaryColor }}>
          Create signature
        </Text>

        {showScanner && (
          <Scanner
            key={Math.random()}
            onSuccess={(data) => {
              try {
                const obj = JSON.parse(data);
                const o = obj.orderRef;
                setOrderRef(o);
              } catch (e) {}
              setShowScanner(false);
            }}
          ></Scanner>
        )}
        <View style={{ maxWidth: 220 }}>
          <Button
            title={showScanner === false ? "Scan QR code" : "Stop scanning"}
            buttonStyle={{ maxWidth: 220, padding: 10, margin: 0 }}
            style={{ maxWidth: 220 }}
            onPress={() => {
              setShowScanner(!showScanner);
            }}
          >
            Scan
          </Button>
        </View>
        <Spacer large />

        <LabelWithIcon
          labelText="Address"
          icon={
            <Icon
              size={14}
              reverse
              name="file-copy"
              color={theme.palette.primaryColor}
              onPress={() => {
                Clipboard.setString(address);
              }}
            />
          }
        ></LabelWithIcon>
        <Text style={{ color: theme.palette.primaryColor }}>{address}</Text>
        <Spacer />

        <LabelWithIcon
          labelText={"NFT / Unique Asset"}
          icon={
            <Icon
              size={14}
              reverse
              name="file-copy"
              color={theme.palette.primaryColor}
              onPress={() => {
                Clipboard.setString(signInInfo.uniqueAsset);
              }}
            />
          }
        />
        <Text style={{ color: theme.palette.primaryColor }}>
          {signInInfo.uniqueAsset}
        </Text>
        <Spacer />

        <Text h4 style={{ color: theme.palette.primaryColor }}>
          Message
        </Text>
        <TextInput
          style={{
            borderColor: "black",
            borderRadius: 10,
            borderWidth: 2,
            fontSize: 20,
            height: 100,
            padding: 10,
          }}
          name="message"
          multiline={true}
          numberOfLines={4}
          onChangeText={(text) => setMessage(text)}
          value={message}
        />
        <Spacer />

        <LabelWithIcon
          labelText={"Signature"}
          icon={
            <Icon
              size={14}
              reverse
              name="file-copy"
              color={theme.palette.primaryColor}
              onPress={() => {
                Clipboard.setString(signature);
              }}
            ></Icon>
          }
        />

        <TextInput
          style={{
            borderColor: "black",
            borderWidth: 2,
            borderRadius: 10,
            padding: 10,
            fontSize: 20,
          }}
          name="signature"
          onChangeText={(text) => setSignature(text)}
          value={signature}
        />
        <Spacer />

        <View style={{ display: "flex", flexDirection: "row" }}>
          {orderRef && (
            <Button
              title="Sign in"
              titleStyle={titleStyle}
              onPress={() => {
                const url = "https://idp.ravenrebels.com/authenticate";
                const obj = {
                  address,
                  signature,
                  message,
                  uniqueAsset: signInInfo.uniqueAsset,
                  nft: signInInfo.uniqueAsset,
                  orderRef,
                };
                const promise = axios.post(url, obj);
                promise.then((data) => {
                  onSignedIn();
                });
              }}
            ></Button>
          )}

          <CopyButton
            onPress={() => {
              const obj = {
                address,
                signature,
                message,
                uniqueAsset: signInInfo.uniqueAsset,
                nft: signInInfo.uniqueAsset,
              };

              const json = JSON.stringify(obj, null, 4);
              Clipboard.setString(json);
            }}
          />
        </View>
      </Board>
    </View>
  );
}

function LabelWithIcon({ labelText, icon }) {
  return (
    <View style={{ display: "flex", flexDirection: "row" }}>
      <View>
        <Text h4 style={{ display: "flex", color: theme.palette.primaryColor }}>
          {labelText}
        </Text>
      </View>
      <View>{icon}</View>
    </View>
  );
}

function CopyButton({ onPress }) {
  return (
    <Button
      onPress={onPress}
      titleStyle={titleStyle}
      title="copy all fields"
      icon={
        <Icon name="file-copy" color="white" style={{ marginRight: 10 }}></Icon>
      }
    ></Button>
  );
}
