import RavencoinReader from "@ravenrebels/ravencoin-reader";

export async function fetchMetaDataForAsset(assetName) {
  return fetchMetaDataForAssetLive(assetName);
}
export async function getAddressForAsset(assetName) {
  const asdf = await RavencoinReader.getAddressesByAsset(assetName);
 console.log("asdf", asdf);
  const addresses = Object.keys(asdf);
  console.log("Addresses", addresses);
  if (addresses.length > 0) {
    return addresses[0];
  }
  return null;
}
export async function fetchMetaDataForAssetLive(assetName) {
  return RavencoinReader.getAsset(assetName);
}
export async function getBalance(addresses) {
  return RavencoinReader.getAssetBalance(addresses);
}
