import * as React from "react";
import * as DataHandler from "./DataHandler";
const utils = require("./utils");

const cache = {};

export default function useAssetMetaData(balance) {
  const [lastUpdated, setLastUpdated] = React.useState(new Date());
 

  balance.map((asset) => {

    
    const name = asset.assetName;

    if(name === "RVN"){
      return;
    }
    //Check if we already have fetched meta data for this asset
    if (cache[name]) {
      return;
    }

    //To prevent loading multiple times, add an empty object as meta, will be over written when ajax request is done
    cache[name] = {};
    console.log("FETCH meta data for", name);
    DataHandler.fetchMetaDataForAsset(asset.assetName).then((result) => {
      cache[name] = result;
      setLastUpdated(new Date());
    });
  });

  return cache;
}
