import React from "react";
import { Input, Text } from "react-native-elements";
import { View, StyleSheet, Image } from "react-native";
import Button from "../components/Button";
import theme from "../theme.json";
const EncryptionUtils = require("../EncryptionUtils");
import utils from "../utils";

function Disclaimer() {
  const source = require("../assets/splash.png");
  const styles = StyleSheet.create({
    container: {
      paddingTop: 50,
      marginLeft: "auto",
      marginRight: "auto",
      opacity: 0.4,
      flex: 2,
    },

    logo: {
      width: 100,
      height: 100,
    },

    disclaimerText: {
      color: theme.palette.primaryColor,
    },
  });

  return (
    <View style={styles.container}>
      <Image source={source} style={styles.logo} />
      <Text style={styles.disclaimerText}>pre-beta</Text>
      <Text style={styles.disclaimerText}>@RavenRebels</Text>
    </View>
  );
}

/*
    Scenario 1: fresh start.
    User has never logged in.
    No password, no mnemonic.
    User picks a password, user create a new wallet

    Scenario 2: User re-login
    User logs in again,
    User has password
    User has mnemonic.
    At login check that password can decrypt the mnemonc
    If wrong password, user tries again.

    Scenario 3:
    User has logged in.
    User logs out.

    Scenario 4, user changes her password.


*/

function MainView({ mnemonic, setMnemonic, setPassword }) {
  const hasUsedTheApp = mnemonic !== utils.mnemonicDefault;

  if (hasUsedTheApp === false) {
    return (
      <SetPassword
        setMnemonic={setMnemonic}
        setPassword={setPassword}
      ></SetPassword>
    );
  }
  if (hasUsedTheApp === true) {
    return (
      <EnterPassword
        setPassword={setPassword}
        setMnemonic={setMnemonic}
        mnemonic={mnemonic}
      />
    );
  }
}
export function Entrance({ mnemonic, setMnemonic, setPassword }) {
  const hasUsedTheApp = mnemonic !== utils.mnemonicDefault;

  return (
    <View style={{ display: "flex", flexDirection: "column", marginTop: 50 }}>
      <MainView
        mnemonic={mnemonic}
        setMnemonic={setMnemonic}
        setPassword={setPassword}
      ></MainView>
      <Disclaimer />
    </View>
  );
}

const parentStyles = {
  // display: "flex",
  //justifyContent: "center",
};
const parentSubStyles = {
  marginTop: 40,
  backgroundColor: "white",
  borderRadius: 20,
  margin: "auto",
  maxWidth: 300,
  color: theme.palette.primaryColor,
};

function Heading(props) {
  return (
    <Text
      h4
      style={{
        color: theme.palette.primaryColor,
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: 50,
      }}
    >
      {props.children}
    </Text>
  );
}
function EnterPassword({ mnemonic, setMnemonic, setPassword }) {
  const [inputValue, setInputValue] = React.useState(null);
  return (
    <View style={parentStyles}>
      <View style={parentSubStyles}>
        <View>
          <View>
            <Heading>Enter local password</Heading>
          </View>
          <View
            style={{
              color: theme.palette.primaryColor,
              flex: 2,
              justiyContent: "space-around",
              margin: "auto",
            }}
          >
            <Input
              placeholder="Enter password"
              style={{ marginTop: 50, padding: 10, backgroundColor: "white" }}
              secureTextEntry={true}
              onChange={(event) => {
                const value = event.target.value;
                setInputValue(value);
              }}
            />
            <Button
              style={{
                minWidth: 100,
                minHeight: 100,
                backgroundColor: "hotpink",
                color: "white",
              }}
              title="Sign in"
              onPress={async () => {
                //Validate, can we decrypt the mnemonic with this password?

                try {
                  const decrypted = await EncryptionUtils.aesGcmDecrypt(
                    mnemonic,
                    inputValue
                  );

                  setPassword(inputValue);
                  setMnemonic(decrypted);
                } catch (e) {
                  console.dir(e);
                  alert("Wrong password");
                }
              }}
            ></Button>
          </View>
          <View style={{ backgroundColor: theme.palette.primaryColor }}></View>
        </View>
      </View>
    </View>
  );
}

function SetPassword({ mnemonic, setMnemonic, setPassword }) {
  const [p1, setP1] = React.useState("");
  const [p2, setP2] = React.useState("");

  const submit = () => {
    if (!p1) {
      return;
    }

    if (!p2) {
      return;
    }

    if (p1.length < 4) {
      alert("Please enter more than 4 characters");
      return;
    }
    if (p1 === p2) {
      setPassword(p1);
      //Set password means that there are no mnemonic, new
      setMnemonic(utils.mnemonicDefault);
    } else {
      alert("Passwords do not match");
    }
  };
  const hideMode = true;
  return (
    <View style={parentStyles}>
      <View style={parentSubStyles}>
        <Heading>Welcome</Heading>
        <Input
          value={p1 || ""}
          enterkeyhint="next"
          label={"Set local password"}
          autoCapitalize="none"
          onChangeText={setP1}
          secureTextEntry={hideMode}
        />
        <Input
          autßoCapitalize="none"
          inputStyle={{ padding: 10 }}
          value={p2 || ""}
          enterkeyhint="next"
          label={"Type in password again please"}
          onChangeText={setP2}
          secureTextEntry={hideMode}
        />

        <Button title="Go" onPress={submit}></Button>
      </View>
      <View style={{ width: "10%" }}></View>
    </View>
  );
}
