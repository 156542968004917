/*

According to BIP44, 
We should start checking balances of addresses from position 0.
When we have found 20 unused addresses we should stop checking.

We would like the answer the question
"Do we have 20 unused addresses?"

1) We must have generated 20 addresses
2) We must have checked the balance of these 20 addresses.
    That is HISTORY, an address can have zero balance but have been used

*/

export default function useDoWeNeedMoreAddresses(addresses, balanceInfo) {
  if (!balanceInfo) {
    return false;
  }
  if (!addresses) {
    return true;
  }
  if (addresses.length === 0) {
    return true;
  }
  //Just to be safe
  if (addresses.length > 8800) {
    return false;
  }
  //No need to derive more addresses when we are updating balance
  if (balanceInfo.pendingUpdate) {
    return false;
  }
  const balance = balanceInfo.balance;

  if (!balance) {
    return false;
  }

  //If balance is an empty object
  if (Object.keys(balance).length === 0) {
    return false;
  }

  let numberOfUnusedAddressesInARow = 0;

  for (let address of addresses) {
    const b = balance[address];
    if (b === undefined) {
      return false; //No need to generate addresses if some balance has not arrived yet
    }

    const hasHistory = b.length > 0;

    if (hasHistory) {
      numberOfUnusedAddressesInARow = 0;
    } else {
      numberOfUnusedAddressesInARow = numberOfUnusedAddressesInARow + 1;
    }
  }

  if (numberOfUnusedAddressesInARow < 40) {
    return true;
  }
  return false;
}
