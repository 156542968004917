import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet, Button } from "react-native";

import { Camera, CameraType } from "expo-camera";
import { BarCodeScanner } from "expo-barcode-scanner";

export default function Scanner({ onSuccess }) {
  const [scanned, setScanned] = useState(false);
  const [data, setData] = React.useState(null);

  const handleBarCodeScanned = (data) => {
    //Check if data is text or json

    setScanned(true);
    setData(data);
    onSuccess(data);
  };

  return (
    <View style={styles.container}>
      <Text>Scanner</Text>
      <View style={{ flex: 1 }}>
        {scanned === false && (
          <SmartphoneQR handleBarCodeScanned={handleBarCodeScanned} />
        )}
      </View>
      <View>
        {scanned && (
          <Button
            title={"Tap to Scan Again"}
            onPress={() => setScanned(false)}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  scanner: {
    flex: 1,
  },
  container: {
    flexDirection: "column",
    minHeight: 200,
  },
});

function SmartphoneQR({ handleBarCodeScanned }) {
  const [type, setType] = useState(CameraType.back);

  // const [hasPermission, setHasPermission] = useState(null);
  const [hasPermission, setHasPermission] = useState(true);
  const [scanned, setScanned] = useState(false);

  const cameraRef = React.useRef();

  if (scanned === true) {
    return <Text>Scanned is true</Text>;
  }
  if (hasPermission === null) {
    return <Text>Requesting for camera permission</Text>;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  function toggleCameraType() {
    setType((current) =>
      current === CameraType.back ? CameraType.front : CameraType.back
    );
  }

  try {
    return (
      <View style={styles.container}>
        <Button
          title="Switch camera orientation"
          onPress={toggleCameraType}
        ></Button>
        <Camera
          style={{ width: 200, height: 200 }}
          ref={cameraRef}
          onBarCodeScanned={(d) => {
            setScanned(true);
            handleBarCodeScanned(d.data);
          }}
          barCodeScannerSettings={{
            barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
          }}
        ></Camera>
        {scanned && (
          <Button
            title={"Tap to Scan Again"}
            onPress={() => setScanned(false)}
          />
        )}
      </View>
    );
  } catch (e) {
    alert("" + e);
  }
}
